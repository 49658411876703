.app-container {
  height: 100vh;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  white-space: pre-line;
}

.container {
  height: 100vh;
  

  .image-logo {
    position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    img {
      height: 20em;
    }
  }

  @keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }

  .background {
    //position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffff;
    overflow: hidden;
  }

  .background span {
    width: 27vmin;
    height: 27vmin;
    border-radius: 27vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 21;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .background span:nth-child(0) {
    color: #4cc9f1;
    top: 89%;
    left: 70%;
    animation-duration: 254s;
    animation-delay: -47s;
    transform-origin: -19vw -5vh;
    box-shadow: -54vmin 0 7.4164043467852805vmin currentColor;
  }
  .background span:nth-child(1) {
    color: #4261ed;
    top: 71%;
    left: 26%;
    animation-duration: 270s;
    animation-delay: -156s;
    transform-origin: -4vw -12vh;
    box-shadow: 54vmin 0 6.846050285272553vmin currentColor;
  }
  .background span:nth-child(2) {
    color: #7208b6;
    top: 80%;
    left: 80%;
    animation-duration: 135s;
    animation-delay: -8s;
    transform-origin: -5vw -10vh;
    box-shadow: -54vmin 0 7.338211862324823vmin currentColor;
  }
  .background span:nth-child(3) {
    color: #4cc9f1;
    top: 54%;
    left: 68%;
    animation-duration: 86s;
    animation-delay: -244s;
    transform-origin: 16vw 15vh;
    box-shadow: 54vmin 0 7.201615144814094vmin currentColor;
  }
  .background span:nth-child(4) {
    color: #4cc9f1;
    top: 17%;
    left: 34%;
    animation-duration: 267s;
    animation-delay: -283s;
    transform-origin: 6vw 4vh;
    box-shadow: 54vmin 0 7.739976758519674vmin currentColor;
  }
  .background span:nth-child(5) {
    color: #7208b6;
    top: 2%;
    left: 1%;
    animation-duration: 12s;
    animation-delay: -16s;
    transform-origin: -10vw 18vh;
    box-shadow: 54vmin 0 7.0699290991468935vmin currentColor;
  }
  .background span:nth-child(6) {
    color: #4cc9f1;
    top: 9%;
    left: 59%;
    animation-duration: 27s;
    animation-delay: -144s;
    transform-origin: -20vw 25vh;
    box-shadow: -54vmin 0 7.091015546723484vmin currentColor;
  }
  .background span:nth-child(7) {
    color: #4261ed;
    top: 40%;
    left: 36%;
    animation-duration: 66s;
    animation-delay: -247s;
    transform-origin: -20vw -21vh;
    box-shadow: 54vmin 0 7.367879582426651vmin currentColor;
  }
  .background span:nth-child(8) {
    color: #4261ed;
    top: 2%;
    left: 11%;
    animation-duration: 230s;
    animation-delay: -8s;
    transform-origin: -10vw 13vh;
    box-shadow: 54vmin 0 6.911789492392951vmin currentColor;
  }
  .background span:nth-child(9) {
    color: #7208b6;
    top: 15%;
    left: 40%;
    animation-duration: 231s;
    animation-delay: -46s;
    transform-origin: 10vw 19vh;
    box-shadow: 54vmin 0 7.124454266066814vmin currentColor;
  }
  .background span:nth-child(10) {
    color: #7208b6;
    top: 93%;
    left: 25%;
    animation-duration: 78s;
    animation-delay: -100s;
    transform-origin: -15vw -6vh;
    box-shadow: -54vmin 0 6.868336850248699vmin currentColor;
  }
  .background span:nth-child(11) {
    color: #7208b6;
    top: 97%;
    left: 75%;
    animation-duration: 207s;
    animation-delay: -225s;
    transform-origin: 2vw -17vh;
    box-shadow: -54vmin 0 7.2268332039360645vmin currentColor;
  }
  .background span:nth-child(12) {
    color: #7208b6;
    top: 48%;
    left: 58%;
    animation-duration: 100s;
    animation-delay: -41s;
    transform-origin: 14vw 19vh;
    box-shadow: -54vmin 0 7.1480241407037965vmin currentColor;
  }
  .background span:nth-child(13) {
    color: #4cc9f1;
    top: 86%;
    left: 84%;
    animation-duration: 285s;
    animation-delay: -93s;
    transform-origin: -15vw -6vh;
    box-shadow: 54vmin 0 7.401272391849407vmin currentColor;
  }
  .background span:nth-child(14) {
    color: #4261ed;
    top: 20%;
    left: 92%;
    animation-duration: 107s;
    animation-delay: -78s;
    transform-origin: -21vw 1vh;
    box-shadow: 54vmin 0 7.4521782547594615vmin currentColor;
  }
  .background span:nth-child(15) {
    color: #4261ed;
    top: 67%;
    left: 50%;
    animation-duration: 136s;
    animation-delay: -73s;
    transform-origin: -17vw 7vh;
    box-shadow: 54vmin 0 7.293225549474615vmin currentColor;
  }
}

img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
