@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.clients {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  &__title {
    text-align: center;
    color: $dark-grey; // $ultramarine-blue;
    h1 {
      font-weight: 200;
      font-size: 2.6rem;
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

}

.client {
  cursor: pointer;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 15em;
  
  &__logo {
    width: 6em;
    height: 6em;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; 
    //background-size: cover;

   // border: 3px solid $trypan-blue; // white;
  
    img {
      width: 5em;
    }
  }

  &__name {
    
    h3 {
      font-weight: 400 !important;
    }
  }

  &__description {
    span {
      font-weight: 200;
    }
  }
}


@media (max-width: 500px) {
  .clients {
  
    &__title {
      text-align: center;
      color: $dark-grey; // $ultramarine-blue;
      h1 {
        font-weight: 200;
        font-size: 2rem;
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
  
  }
}