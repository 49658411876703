@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.footer {
  z-index: 999 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  background-color: $ultramarine-blue; //$purple;
  color: white;
  min-height: 5em;

  
  //position: fixed;
  //bottom: 0;
  // left: 50%;
  // -webkit-transform: translate(-50%, -0%);
  // transform: translate(-50%, -0%);
  text-align: center;
}
