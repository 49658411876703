@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.landing-page {
  display: flex;
  flex-direction: column;

  background-image: url("../../assets/images/Shutterstock_270177362.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  margin-top: 0 !important;
  padding-top: 0 !important;

  &__title-section {
    display: flex;
    flex-direction: row;

    &--text {
      flex: 0;
      text-align: left;
      min-width: 40em;
      border-radius: 10px;
      margin-left: 1em;
      p {
        line-height: 1.5em;
        color: $ultramarine-blue;
        font-weight: 400;
        font-size: 2rem;
        padding: 1em;
        margin-top: 0;
      }
    }

    &--image {
      flex: 0;
    }
  }
}

.text-section {

  text-align: center;
  color: white;

  &__heading {
    padding-top: 2em;
    padding-left: 15%;
    padding-right: 15%;
  
    h1 {
      font-weight: 200;
      font-size: 2.6rem;
    }
  }
  &__body {
    padding-bottom: 2em;
    padding-left: 15%;
    padding-right: 15%;
    h3 {
      font-weight: 200;
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }
}


@media (max-width: 500px) {
  .text-section {

    &__heading {
      padding-top: 2em;
      padding-left: 5%;
      padding-right: 5%;
    
      h1 {
        font-weight: 200;
        font-size: 2rem;
   
      }
    }
    &__body {
      padding-bottom: 2em;
      padding-left: 5%;
      padding-right: 5%;
      
      h3 {
        font-weight: 200;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}