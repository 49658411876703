@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

$sectionTitleHeight: 30em;

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  &__text {
    text-align: center;
    color: white; 

    h1 {
      font-weight: 200 !important;
      font-size: 3rem;
    }
  }
}

@media (max-width: 500px) {
  .section-title {
    
    &__text {
      padding-left: 5%;
      padding-right: 5%;
      h1 {
        font-weight: 200 !important;
        font-size: 2.2rem;
      }
    }
  }
}

