$text-primary: #3F3F3F; //  #f2f2f2;
$background-color: white;
$background-color-outer: #898989; //rgba(114,9,163,0.5); // #7209B7; // #4F565E;

// Zuglabs colors
$light-grey: #898989;
$dark-grey: #3F3F3F;
$purple: #7209B7;
$trypan-blue: #3A0CA3;
$ultramarine-blue: #4361EE;
$vivid-sky-blue: #4CCAF0;


$black: black;
$dark-grey: #333333;
$medium-gray: #808080;
//$light-grey: #D0D0D0;
$blue: #288bc2;
$blue-secondary: #6897BB;
$orange: #F7913E;
$pass-background:#f2f2f2;
$success: #62BD46;
$important: #EA3339;
$stripe: #FEF200;
$neutralgray: #B3B3B3;
$buttonGray: #83888E;

$whiteOpacity09: rgba(255, 255, 255, 0.9);
$blackOpacity05: rgba(0, 0, 0, 0.5);
$max-width: 80em;





