.contact-us {
  
  color: white !important;
  font-size: medium;
  line-height: 0.3rem;

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }

  h3, h4 {
    font-weight: 400;
  }
  h4 {
    font-weight: 200;
    cursor: pointer;
  }
}