.header {
  box-shadow: 0 0 3px rgba(60,72,88,.15);
  width: 100%;
  height: 4.8em;
  padding-top: 0.5em;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0 !important;

  //position: sticky !important;


  justify-items: center;


  &__logo {
    margin-left: 2em;
   
    img {
      height: 4em;
    }
  }

  &__wallet {
     margin-right: 2em;
  }
}
