

@import "./assets/scss/variables";
@import "./assets/scss/mixins";

body {
  margin: auto;
  max-width: $max-width;
  z-index: 1;

  font-family: "Inter", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color-outer;

  //height: 100vh;
  // background: radial-gradient($ultramarine-blue, $dark-grey);
  // -webkit-overflow-y: hidden;
  // -moz-overflow-y: hidden;
  // -o-overflow-y: hidden;
  // //overflow-y: hidden;
  // -webkit-animation: fadeIn 1 1s ease-out;
  // -moz-animation: fadeIn 1 1s ease-out;
  // -o-animation: fadeIn 1 1s ease-out;
  // animation: fadeIn 1 1s ease-out;

  




}

