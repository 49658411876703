@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.features {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  
  z-index: 11;
}

.feature {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;

  background-color: $ultramarine-blue; 
  padding: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
  
  &__icon {
    font-size: 3.5rem;
  }
  
  &__title {
    h4 {
      font-weight: 400;
    }
  }
  &__description {

    span {
      font-size: 0.85rem;
      font-weight: 200;
    }
  }

  &:hover {
    background-color: $purple;
    color: white; 
  }
}

@media (max-width: 500px) {
  
  .feature {

    &__description {
      padding-left: 10%;
      padding-right: 10%;

      span {
        font-size: 0.85rem;
        font-weight: 200;
      }
    }
  
    &:hover {
      background-color: $purple;
      color: white; 
    }
  }
  
  
  .features {
    flex-direction: column;
  }
}
